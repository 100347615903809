@font-face {
  font-family: "Roboto-Condensed";
  src: url("../fonts/RobotoCondensed-Regular.ttf");
}

@font-face {
  font-family: "Roboto-Condensed-Light-Italic";
  src: url("../fonts/RobotoCondensed-LightItalic.ttf");
}

@font-face {
  font-family: "Roboto-Condensed-Light";
  src: url("../fonts/RobotoCondensed-Light.ttf");
}

@font-face {
  font-family: "Roboto-Condensed-Italic";
  src: url("../fonts/RobotoCondensed-Italic.ttf");
}

@font-face {
  font-family: "Roboto-Condensed-Bold-Italic";
  src: url("../fonts/RobotoCondensed-BoldItalic.ttf");
}

@font-face {
  font-family: "Roboto-Condensed-Bold";
  src: url("../fonts/RobotoCondensed-Bold.ttf");
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("../fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto-Italic";
  src: url("../fonts/Roboto-Italic.ttf");
}

@font-face {
  font-family: "Roboto-Light";
  src: url("../fonts/Roboto-Light.ttf");
}

@font-face {
  font-family: "Roboto-LightItalic";
  src: url("../fonts/Roboto-LightItalic.ttf");
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: "Roboto-MediumItalic";
  src: url("../fonts/Roboto-MediumItalic.ttf");
}

@font-face {
  font-family: "Roboto-Thin";
  src: url("../fonts/Roboto-Thin.ttf");
}

@font-face {
  font-family: "Roboto-ThinItalic";
  src: url("../fonts/Roboto-ThinItalic.ttf");
}

@font-face {
  font-family: "Roboto-Black";
  src: url("../fonts/Roboto-Black.ttf");
}

@font-face {
  font-family: "Roboto-BlackItalic";
  src: url("../fonts/Roboto-BlackItalic.ttf");
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("../fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: "Roboto-BoldItalic";
  src: url("../fonts/Roboto-BoldItalic.ttf");
}

a {
  outline: none !important;
}

.display_block {
  display: block;
}
.display_none {
  display: none;
}

body {
  background-color: #fff;
  color: #3b3936;
  padding: 0px;
  margin: 0px;
  font-family: "Roboto-Condensed", "arial", sans-serif;
}

.no-padding {
  padding: 0px;
}

.no-margin {
  margin: 0px;
}

.no-padding-margin {
  padding: 0px;
  margin: 0px;
}
.section_title {
  font-family: "roboto-condensed-bold", Arial;
  font-size: 17pt;
  color: #64686e;
}
.read_more {
  color: #2675b1;
  font-family: "roboto-condensed-bold", Arial;
  font-size: 13pt;
}
.read_more a:link {
  color: #2675b1;
  text-decoration: none;
}

.read_more a:visited {
  color: #2675b1;
  text-decoration: none;
}

.read_more a:hover {
  color: #2675b1;
  text-decoration: underline;
}

.read_more a:active {
  color: #2675b1;
  text-decoration: none;
}

/*Header*/
.nav_brand_img {
  float: left;
  border: solid red 0px;
  margin-left: -8px;
}

.header h6 {
  padding: 0px;
  margin: 0px;
}
.container .header {
  padding-top: 15px;
  border: solid red 0px;
}
.header div {
  font-family: "Roboto-Italic", "arial";
  font-size: 9.5pt;
  color: #6f7071;
  font-weight: normal;
}
.header span {
  background-image: url("../images/inbox_icon.png");
  background-position: 0px 0px;
  text-indent: 25px;
  background-repeat: no-repeat;
  color: #2675b1;
  font-size: 9.5pt;
  font-weight: normal;
  font-family: "Roboto-Regular", "arial";
}

.top_search div.pull-left {
  font-family: "Roboto-Italic", "arial";
  font-size: 9.5pt;
  color: #6f7071;
  margin-top: 15px;
}

.top_search span.pull-left {
  margin-top: 15px;
  text-indent: 25px;
  background-position: 0px 0px;
  background-repeat: no-repeat;
  font-size: 9.5pt;
  color: #2675b1;
  background-image: url("../images/inbox_icon.png");
  font-family: "Roboto-Regular", "arial";
}

.menu_container {
  padding: 10px 20px 5px 15px;
}
.menu_container .navbar-default {
  background-color: #fff;
  border: 0px;
  border-radius: 0px;
}
.menu_container .navbar {
  margin: 0px;
  border: 0px;
  border-radius: 0px;
  padding-top: 5px;
}

.menu_container .navbar .navbar-brand {
  padding: 0px;
}

.menu_container .navbar .navbar-nav {
  border: solid red 0px;
  margin-left: 0px;
  padding-top: 0px;
}
.menu_container .navbar .navbar-nav li {
  border: solid red 0px;
  padding-top: 15px;
}
.menu_container .navbar .navbar-nav li a {
  font-weight: normal;
  color: #5f5f5f;
  font-family: "Roboto-Condensed", "arial";
  font-size: 14pt;
  text-transform: uppercase;
}

.menu_container .navbar .navbar-nav li.padding-diff {
  padding-left: 0px;
}
.menu_container .navbar .navbar-nav li.padding-diff1 {
  padding-left: 0px;
}

.navbar-default .navbar-right .search_box {
  border: solid #e8e8e8 1px;
  height: 45px;
  width: 100%;
}

.navbar-default .navbar-right .form-control {
  border: 0px;
  border-radius: 0px;
  box-shadow: none;
  padding-left: 0px;
}

.menu_container .btn {
  padding-left: 12px;
  padding-right: 20px;
}

.search_icon {
  float: left;
  width: 15%;
  font-size: 25px;
  color: #6f9ec2;
  border: solid red 0px;
}
.navbar-default .navbar-right .form-control {
  border: 0px;
  border-radius: 0px;
  box-shadow: none;
  width: 85%;
  height: 45px;
}

.btn-default {
  background-color: #ffffff;
  border: 0px;
  outline: none;
}
.btn-default:focus,
.btn-default.focus {
  background-color: #fff;
  border: 0px;
  outline: none;
}
.btn-default:hover {
  background-color: #fff;
  border: 0px;
  outline: none;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  background-color: #fff;
  border: 0px;
  outline: none;
}
.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus {
  background-color: #fff;
  border: 0px solid red;
  box-shadow: none;
  outline: none;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  background: none;
  outline: none;
}
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus {
  background-color: #fff;
  border: 0px;
  outline: none;
}

.btn-default:focus,
.btn-default.focus {
  background-color: #fff;
  border: 0px;
  outline: none;
}

.menu_container .navbar-toggle {
  padding-left: 15px;
  border-radius: 0px;
}
.mobile_menu {
  padding-left: 10px;
  padding-right: 10px;
}
.menu_arrow {
  padding-right: 10px;
  font-size: 14px;
  padding-left: 10px;
  line-height: 25px;
  color: #878a8f;
}
.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border: 0px;
  border-radius: 0px;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background: none;
}

.mobile_menu {
  font-family: "Roboto-Condensed";
  font-size: 14pt;
  color: #5f5f5f;
}
/*Header*/

/*Slider*/
.white .sa_owl_theme .owl-nav button {
  overflow: hidden;
  outline: none;
}

.owl-carousel .owl-item img {
  height: 309px !important;
  width: 210px !important;
}

.sa_owl_theme .owl-dots .owl-dot {
  outline: none;
}

.slider_bg {
  background: #fff url("../images/slider_bg.png");
  min-height: 382px;
  background-repeat: repeat-x;
}

.slider_img {
  margin: 0px auto;
}

.slider_content {
  margin-top: 55px;
  padding-left: 0px;
  padding-right: 0px;
}

.slider_padding {
  padding: 40px 20px 0px 15px;
  border: solid red 0px;
}
.slider_content h1 {
  padding: 10px 0px 0px 0px;
  margin: 0px;
  color: #2675b1;
  font-family: "Roboto-Condensed-Light", "arial";
  font-size: 30px;
  border: solid red 0px;
  text-align: center;
}
.slider_content h3 {
  color: #2675b1;
  font-family: "Roboto-Regular", "arial";
  font-size: 15px;
  padding: 8px 0px 25px 0px;
  margin: 0px;
  text-align: center;
}
.slider_content h6 {
  color: #7b7c7d;
  margin: 0px;
  font-family: "Roboto-Italic", "arial";
  font-size: 17px;
  line-height: 21px;
  padding: 0px 0px 10px 0px;
}
.slider_author_name {
  color: #8f9297;
  font-family: "Roboto-Regular", "arial";
  font-size: 14px;
  width: 100%;
}
/*Slider*/

/*Highlights*/
.highlight_border {
  border-bottom: solid #dbdbdb 1px;
  padding-bottom: 50px;
}

.highlights_review_bg {
  background: #f8f7f5;
}

.lable_hlight,
.series_title {
  color: #64686e;
  font-family: "Roboto-Condensed-Bold", "arial";
  font-size: 24px;
}
.subject_hightlights .highlight_book_title,
.book_container .highlight_book_title {
  clear: both;
  width: 85%;
  font-family: "Roboto-Regular", "arial";
  line-height: 20px;
  font-size: 17px;
  color: #2675b1;
  border: solid red 0px;
}
.highlights_review_bg .highlight_book_title {
  clear: both;
  width: 80%;
  font-family: "Roboto-Regular", "arial";
  line-height: 20px;
  font-size: 17px;
  color: #2675b1;
  padding-top: 10px;
  padding-bottom: 5px;
}

.highlights_review_bg .highlight_author_name {
  clear: none;
  /*width:90%;*/
  font-family: "Roboto-Light", "arial";
  font-size: 15px;
  line-height: 20px;
  color: #878a8f;
}
.review_container {
  padding-bottom: 20px;
  padding-top: 30px;
}
.review_items {
  padding-top: 30px;
}
.highlightitems {
  padding-top: 30px;
}

/*Highlights*/

/*News*/
.home_news {
  padding-bottom: 20px;
}
.news_bg {
  background: #fff;
  min-height: 226px;
}
.news_padding {
  padding: 20px 20px 20px 15px;
}
.news_content {
  padding-top: 30px;
}

.circle {
  float: left;
  width: 63px;
  height: 63px;
  border-radius: 50%;
  color: #d1d1d1;
  border: #c4c4c4 1px solid;
  text-align: center;
  background: #fff;
}

.circle span {
  color: #64686e;
  text-transform: uppercase;
  font-family: "Roboto-Condensed-Light", Arial;
  float: left;
  text-align: center;
  margin: 0px auto;
  border: solid red 0px;
  width: 100%;
}

.circle .ndate {
  font-size: 14pt;
  padding-top: 7px;
}
.circle .nmonth {
  font-size: 11pt;
  padding-top: 0px;
}

.news_content .news_title {
  width: 70%;
  text-align: left;
  padding-left: 10px;
  padding-top: 4px;
  border: solid red 0px;
  font-family: "Roboto-Condensed-Light", Arial;
  font-size: 15pt;
  line-height: 24px;
  color: #2675b1;
}
.news_title a:link {
  color: #2675b1;
  text-decoration: none;
}
.news_title a:visited {
  color: #2675b1;
  text-decoration: none;
}
.news_title a:hover {
  color: #2675b1;
  text-decoration: none;
  border-bottom: 1px solid #2675b1;
}
.news_title a:active {
  color: #2675b1;
  text-decoration: none;
}
/*News*/

/*Footer*/
.footer_hr {
  border-bottom: solid #a6c8e2 1px;
  padding-bottom: 60px;
}
.footer_container {
  background: #6f9ec2;
}
.footer_container .resource_connects p {
  color: #fff;
  font-size: 18pt;
  font-family: "Roboto-Condensed", Arial;
  padding: 0px;
  text-align: center;
}

.resource_connects div.fmenus {
  color: #fff;
  text-indent: 30px;
  font-size: 14pt;
  padding-top: 20px;
  font-family: "Roboto-Condensed-Light", Arial;
  border: solid red 0px;
  padding-left: 25px;
}
.footer_container .resource_connects a {
  color: #fff;
}

p.region {
  float: none;
  margin-right: 0px;
}

.region_container {
  padding-top: 50px;
}

.select_region {
  float: none;
  width: 150px;
  margin: 0px auto;
}
.footer_container .change_region_box {
  font-family: "Roboto-Condensed", Arial;
  font-size: 10pt;
  text-align: center;
  width: 100%;
  float: left;
  margin-left: 0px;
  margin-top: 3px;
  border: 1px solid #a6c8e2;
  padding: 5px 15px;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
}

.footer_container .join-mail {
  color: #c9dded;
  font-size: 14pt;
  padding-top: 0px;
  font-family: "Roboto-Condensed-Light", Arial;
  text-align: center;
}

.footer_container .subscribe_box {
  padding-top: 10px;
}

.subscribe_box .form-control {
  height: 50px;
  border: 0px;
  border-radius: 0px;
}

.subscribe_box .input-group .form-control {
  width: 70%;
}

.unsubscribe .input-group {
  width: 50%;
  float: left;
  margin: 0px auto;
}
.unsubscribe .input-group input {
  border: solid #e8e8e8 1px;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.unsubscribe .input-group-btn {
  float: left;
  width: 30%;
}

.unsubscribe .input-group-btn .btn-secondary {
  width: 100%;
  background-color: #2675b1;
  border: 1px solid #2675b1;
  float: left;
  font-size: 12pt;
  font-family: "Roboto-Condensed-Bold", Arial;
  height: 50px;
  border-radius: 0px;
  color: #fff;
  text-transform: uppercase;
}

.footer_container .input-group {
  width: 90%;
  float: none;
  margin: 0px auto;
}
.footer_container .input-group-btn {
  float: left;
  width: 30%;
}
.footer_container .input-group-btn .btn-secondary {
  width: 100%;
  background-color: #2675b1;
  border: 1px solid #2675b1;
  float: left;
  font-size: 12pt;
  font-family: "Roboto-Condensed-Bold", Arial;
  height: 50px;
  border-radius: 0px;
  color: #fff;
  text-transform: uppercase;
}

.stay_connect {
  padding-left: 0px;
  padding-top: 30px;
}

.footer_container .social_media_container {
  padding-top: 30px;
}
.footer_container .social_media_container1 {
  padding-top: 20px;
}

.social_media_container .polity_img_footer {
  float: left;
  width: 100%;
  text-align: center;
  border: solid red 0px;
}

.social_media_container .polity_img_footer img {
  margin: 0px auto;
}

.polity_footer .breadcrumb > li + li::before {
  content: none;
}

.breadcrumb_right ol {
  float: none;
  width: 100%;
  text-align: center;
  background: none;
  margin-bottom: -12px;
  padding-right: 0px;
  border: solid red 0px;
}
.polity_footer .breadcrumb {
  padding: 5px 0px 0px 0px;
  float: left;
  width: 100%;
  background: none;
  margin-bottom: -12px;
  text-align: center;
  color: #c9dded;
  text-transform: uppercase;
  font-size: 13.9pt;
}
.polity_footer .breadcrumb li {
  padding-right: 10px;
}
.polity_footer .breadcrumb a {
  color: #c9dded;
  text-transform: uppercase;
  font-size: 13.9pt;
}

.breadcrumb_right .breadcrumb a {
  color: #c9dded;
  font-size: 11pt;
}

.social_media_container1 .terms_policy {
  padding-top: 5px;
  border: solid red 0px;
}
.breadcrumb_left {
  margin-top: 14px;
  border: solid red 0px;
}

.social_icons {
  display: table;
  margin: 0px auto;
}
.social_icons .sicons {
  padding-top: 58px;
  float: left;
  padding-left: 10px;
}

.copy_text {
  padding-top: 20px;
  color: #c9dded;
  display: table;
  margin: 0px auto;
  padding-bottom: 20px;
}
.modal-lg {
  width: auto;
}
.modal_region_name {
  font-family: "Roboto-Condensed-Bold", arial;
  font-size: 13pt;
  padding: 10px 0px;
}
.modal_country_name {
  line-height: 24px;
}

/*Footer*/

/*Subject*/
.blog_bg {
  background: #fff url("../images/slider_bg.png");
  height: auto;
  padding-bottom: 20px;
  background-repeat: repeat-x;
  border: solid red 0px;
}

.subject_bg,
.series_bg {
  background: #fff url("../images/slider_bg.png");
  height: auto;
  padding-bottom: 20px;
  background-repeat: repeat-x;
  border: solid red 0px;
}
.subject_padding,
.series_padding {
  padding: 10px 20px 20px 15px;
}
.subject_padding .breadcrumb a,
.series_padding .breadcrumb a {
  color: #808388;
  font-family: "Roboto-Regular", "arial";
  font-size: 14px;
}
.subject_padding .breadcrumb,
.series_padding .breadcrumb {
  background: none;
  padding: 0px;
  margin: 0px;
}
.subject_bg .subject_intro_text,
.series_bg .series_intro_text {
  padding-top: 10px;
}
.subject_bg .subject_intro_text p,
.series_bg .series_intro_text p {
  clear: both;
  padding-top: 10px;
  color: #808388;
  font-family: "Roboto-Regular", "arial";
  font-size: 17px;
  line-height: 23px;
}
.blog_bg .subject_intro_text {
  font-family: "Roboto-Regular", "arial";
  font-size: 17px;
  line-height: 23px;
}
.blog_bg .subject_intro_text p {
  padding-top: 10px;
  color: #808388;
  font-family: "Roboto-Regular", "arial";
  font-size: 17px;
  line-height: 23px;
}

.subject_list_bg {
  background: #f8f7f5;
  padding: 0px 0px 10px 0px;
}
.subject_list_bg .polity_subjects_series {
  padding-top: 0px;
  border: solid red 0px;
}

.subject_list_bg .polity_subjects {
  padding-top: 15px;
  border: solid red 0px;
}

.subject_list_bg .polity_subjects img {
  margin: 0px auto;
  max-width: 100%;
}

.series_bg_color {
  border: solid red 0px;
  background-color: #458992;
  color: #fff;
  width: 100%;
  height: 80px;
  text-align: center;
}

.subject_bg_color {
  border: solid red 0px;
  background-color: #458992;
  color: #fff;
  width: 96%;
  height: 80px;
  text-align: center;
}
.subject_bg_color .subjet-text,
.series_bg_color .subjet-text {
  width: 100%;
  font-size: 18pt;
  line-height: 24px;
  color: #fff;
  vertical-align: middle;
}
.subjet-text a span {
  display: table-cell;
  vertical-align: middle;
}
.subjet-text a {
  vertical-align: middle;
  border: solid red 0px;
  width: 100%;
  height: 80px;
  display: table;
}
.subjet-text a:link {
  color: #fff;
  text-decoration: none;
}
.subjet-text a:visited {
  color: #fff;
  text-decoration: none;
}
.subjet-text a:hover {
  color: #fff;
  text-decoration: none;
}
.subjet-text a:active {
  color: #fff;
  text-decoration: none;
}
/*Subject*/

/*Series*/
.subject_list_bg .polity_series {
  padding-top: 15px;
  border: solid red 0px;
}

.subject_list_bg .polity_series img {
  margin: 0px auto;
  max-width: 100%;
}

.subject_list_bg .series_container {
  min-height: 300px;
}

.subject_list_bg .series_container ul {
  clear: both;
  border: solid red 0px;
  width: 100%;
  padding: 15px 0px 15px 0px;
  margin: 0px;
}

.polity_series .series_listing {
  background-color: #fff;
  float: left;
  width: 95%;
  border: solid red 0px;
}

.subject_list_bg .series_container ul li a {
  font-family: "Roboto-Regular", "arial";
  font-size: 16px;
  color: #2675b1;
}
.subject_list_bg .series_container ul li {
  list-style-type: none;
  font-family: "Roboto-Regular", "arial";
  font-size: 16px;
  color: #2675b1;
  padding-left: 15px;
  line-height: 22px;
}
/*Series*/

/*Blog*/
.subject_padding .blog_container {
  width: 100%;
  float: left;
  border: solid red 0px;
  background: #fff;
  padding: 25px;
  margin: 20px 0px 0px 0px;
}
.blog_container .blog_title,
.author_blog_title h2.blog_title {
  float: left;
  width: 100%;
  border: solid red 0px;
  font-family: "Roboto-Light", arial;
  color: #2675b1;
  font-size: 20px;
  text-indent: 2px;
  padding-top: 5px;
  padding-left: 5px;
}

.blog_container .blog_text {
  font-family: "Roboto-Regular", arial;
  color: #7b7c7d;
  font-size: 16px;
  padding-top: 15px;
}

.blog_container .circle {
  width: 43px;
  height: 43px;
}

.blog_container .circle .ndate {
  font-size: 11pt;
  padding-top: 3px;
}
.blog_container .circle .nmonth {
  font-size: 9pt;
  padding-top: 0px;
}

.author_blog_title .circle {
  width: 43px;
  height: 43px;
}

.author_blog_title .circle .ndate {
  font-size: 11pt;
  padding-top: 3px;
}
.author_blog_title .circle .nmonth {
  font-size: 9pt;
  padding-top: 0px;
}

/*Blog*/

/*Author*/
.author_details .author_blog_link {
  padding-top: 10px;
  clear: both;
}
.author_details .author_designation {
  clear: both;
  font-family: "Roboto-Light", "arial";
  font-size: 14px;
  padding-top: 3px;
  color: #878a8f;
}

.author_details .author_title {
  padding-top: 15px;
  clear: both;
  font-family: "Roboto-Regular", Arial;
  font-size: 17px;
  color: #2675b1;
}
.subject_padding .author_details {
  width: 100%;
}
.subject_padding .author_details .author_no_image {
  font-size: 270px;
  border: solid red 0px;
  min-height: 330px;
}

.blog_author_no_image {
  font-size: 220px;
  border: solid red 0px;
}

.subject_intro_text .author_blog_page {
  padding-top: 10px;
}
.subject_intro_text .author_blog_text {
  padding-left: 0px;
  padding-right: 0px;
  border: solid red 0px;
}
.author_blog_text h1 {
  padding: 0px;
  margin: 0px 0px 0px 0px;
  font-family: "Roboto-Condensed-Light", "arial";
  font-size: 32pt;
  line-height: 25px;
  clear: both;
  color: #000;
  border: solid red 0px;
}
.author_blog_text span {
  clear: both;
  font-family: "Roboto-Regular", "arial";
  font-size: 19px;
  color: #2675b1;
}
.container .author_blog_text h3 {
  clear: both;
  padding: 15px 0px 0px 0px;
  margin: 0px;
  color: #64686e;
  font-family: "Roboto-Bold";
  font-size: 20px;
}
.container .author_blog_text p {
  clear: both;
  font-family: "Roboto-Regular", "arial";
  font-size: 15px;
  padding: 20px 0px 0px 0px;
}
/*Author*/

/*Content*/
.author_blog_text blockquote {
  border-left: 1px solid #eee;
  padding: 0px;
  margin: 0px;
  height: auto;
}
.container .author_blog_text blockquote p {
  padding: 5px 0px 5px 20px;
  float: left;
  line-height: 27px;
  margin: 0px;
  font-family: "Roboto-Light", "arial";
  font-size: 18px;
  padding-top: 3px;
  color: #878a8f;
}
.blockquote_main {
  margin: 2% 0% 0% 3%;
}
.quote_border {
  border-left: solid #878a8f 1px;
}
.omnis_list {
  padding-left: 30px;
  padding-top: 5px;
  font-family: "Roboto-Regular", "arial";
  font-size: 17px;
  color: #878a8f;
}
.omnis_list li {
  line-height: 28px;
}
/*Content*/

/* Book */
.book_detail_page {
  padding-top: 30px;
}
.book_blog_page {
  padding-left: 0px;
  padding-right: 0px;
}
.books_description {
  padding: 0px;
}
.american_century_heading {
  border: solid red 0px;
  font-family: "Roboto-Condensed-Light";
  font-size: 24pt;
  color: #000;
  margin-top: 0px;
  padding: 0px;
  width: 100%;
  text-align: center;
  line-height: 43px;
}
.author_name {
  width: 100%;
  clear: both;
  margin: 0px;
  color: #2675b1;
  font-size: 17pt;
  font-family: "Roboto-Condensed";
  text-align: center;
}
.tag_line_author {
  color: #72757b;
  margin: 0px;
  font-size: 12pt;
  text-align: center;
  width: 100%;
  font-family: "Roboto-Condensed";
}
.show_more_text {
  margin: 10px 0px 0px 0px;
  font-size: 14.5pt !important;
  font-family: "Roboto-Condensed", Arial !important;
  color: #000 !important;
  line-height: 22px;
  position: relative;
}
.fadeBook {
  position: absolute;
  background-image: -moz-linear-gradient(top, transparent, white);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, transparent),
    color-stop(1, white)
  );
  width: 100%;
  bottom: 0px;
  padding-top: 100px;
}
.show_hide_description {
  height: 220px;
}
.show_more_text > * {
  font-size: 14.5pt !important;
  font-family: "Roboto-Condensed", Arial !important;
  color: #000 !important;
}
.show_more_text p {
  float: left;
  font-size: 14.5pt !important;
  color: #000 !important;
  margin: 0px;
  font-family: "Roboto-Condensed", Arial !important;
}
.show {
  float: left;
  font-size: 14.5pt;
  color: #000;
  margin: 0px;
  font-family: "Roboto-Condensed";
}
.showmoretxt {
  float: left;
  font-size: 12pt;
  color: #2675b1;
  clear: both;
  font-family: "Roboto-Condensed";
}
.books_images {
  float: none;
  margin: 0 auto;
  display: table;
}
.buy_now_container {
  background: #fff;
}
.buy_now_order {
  border: solid red 0px;
  margin-left: 0px;
  padding: 0px 0px 0px 0px;
}
.main_container_bg {
  background: #f8f7f5;
  padding: 15px;
}
.button_column {
  padding: 0px;
}
.col-lg-1_vr {
  width: 2%;
  border-right: solid #e3dfd8 1px;
  height: 90px;
  padding: 0px;
}
.buy_now_overall {
  width: 50%;
}
.buy_now_overall1 {
  width: 50%;
}
.buy_now_button {
  text-align: center;
  width: 90%;
  font-family: "Roboto-Condensed-Bold";
  font-size: 14pt;
  color: #fff;
  background: #2675b1;
  padding: 7px 0px;
  margin: 0px;
}
.buy_now_button:link {
  color: #fff;
  background: #2675b1;
  text-decoration: none;
}

.buy_now_button:visited {
  color: #fff;
  background: #2675b1;
  text-decoration: none;
}

.buy_now_button:hover {
  color: #000;
  background: #c9dded;
  text-decoration: none;
}

.buy_now_button:active {
  color: #fff;
  background: #2675b1;
  text-decoration: none;
}
.order_exam_button {
  text-align: center;
  width: 100%;
  font-family: "Roboto-Condensed-Bold";
  font-size: 14pt;
  color: #fff;
  background: #2675b1;
  margin: 0px;
  padding: 7px 0px;
}
.order_exam_button:link {
  color: #fff;
  background: #2675b1;
  text-decoration: none;
}
.order_exam_button:visited {
  color: #fff;
  background: #2675b1;
  text-decoration: none;
}
.order_exam_button:hover {
  color: #000;
  background: #c9dded;
  text-decoration: none;
}
.order_exam_button:active {
  color: #fff;
  background: #2675b1;
  text-decoration: none;
}
.button_tag_name {
  width: 90%;
  text-align: center;
  color: #64686e;
  margin: 0px;
  padding: 5px 0 20px;
}
.order_exam_btn_tag {
  width: 100%;
  text-align: center;
  color: #64686e;
  margin: 0px;
  padding: 5px 0 20px;
}
.default_dektop_padding {
  padding-left: 15px;
  padding-right: 15px;
}
.more_info {
  font-family: "Roboto-Condensed-Bold";
  font-size: 14pt;
  color: #64686e;
}
.books_desc_list {
  list-style: none;
  padding: 0px;
  clear: both;
  margin: 15px 0px 0px 0px;
  width: 100%;
}
.books_desc_list li {
  color: #64686e;
  font-family: "Roboto-Condensed";
  font-size: 11pt;
  line-height: 20px;
}
.visit_books {
  color: #2675b1;
  font-family: "Roboto-Condensed";
  font-size: 11pt;
  width: 100%;
  border-bottom: 0px solid #e3dfd8;
  margin: 15px 0px 0px 0px;
  padding-bottom: 25px;
}
.books_rate_desc li {
  color: #64686e;
  font-family: "Roboto-Condensed";
  font-size: 11pt;
  line-height: 20px;
}
.hardcover_euro {
  color: #64686e;
  margin: 10px 0px 0px 0px;
  font-family: "Roboto-Condensed-Bold" !important;
  font-size: 11pt;
}
.books_rate_desc {
  list-style: none;
  padding: 0px;
  clear: both;
  margin: 5px 0px 0px 0px;
}
.rate_space {
  margin-left: 15px;
}
.reviews_main_container {
  border: solid red 0px;
  padding: 35px 15px;
  margin-left: 0px;
}
.reviews_heading {
  color: #64686e;
  font-family: "Roboto-Condensed-Bold" !important;
  font-size: 18pt;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}
.review_description {
  color: #64686e;
  font-family: "Roboto-Condensed";
  font-size: 12pt;
  line-height: 20px;
  border: solid red 0px;
  padding: 15px 20px 0px 0px;
}
.review_description1 {
  border: solid red 0px;
  padding: 15px 0px 0px 0px;
}
.review_description p {
  color: #64686e;
  font-family: "Roboto-Condensed";
  font-size: 13pt;
  line-height: 20px;
}
.review_description1 p {
  color: #64686e;
  font-family: "Roboto-Condensed";
  font-size: 12pt;
  line-height: 20px;
}
.descriptor_name {
  font-family: "Roboto-Condensed-Bold" !important;
  font-size: 12pt;
  color: #64686e;
}
.journal_name {
  color: #2675b1;
}
.show_more_reviews {
  font-family: "Roboto-Condensed-Bold" !important;
  font-size: 13pt;
  color: #2675b1;
}
.toc_heading {
  color: #64686e;
  margin: 0px 0px 0px 0px;
  font-family: "Roboto-Condensed-Bold" !important;
  font-size: 18pt;
}
.toc_main_container {
  border: solid green 0px;
  padding: 5px 0px 0px 15px;
  margin-left: 0px;
}
.chapter_lists ul li,
.chapter_lists ol li {
  font-family: "Roboto-Condensed";
  font-size: 13pt;
  color: #64686e;
}
.chapter_lists {
  font-family: "Roboto-Condensed";
  font-size: 13pt;
  line-height: 20px;
  clear: both;
  color: #64686e;
  padding: 35px 0 30px;
  width: 95%;
  border-bottom: solid #dbdbdb 0px;
  /*height:190px;overflow:hidden;*/
}
.chapter_lists p {
  color: #64686e;
  clear: both;
  margin: 0px;
  font-family: "Roboto-Condensed";
  font-size: 13pt;
  line-height: 20px;
  /*display:none;*/
}
.toggle_show_more_text {
  color: #2675b1;
  font-size: "Roboto-Condensed", arial;
  font-size: 12pt;
}
.toc_show_more_fade,
.review_show_more_fade {
  background-image: -moz-linear-gradient(top, transparent, white);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, transparent),
    color-stop(1, white)
  );
}
.toc_show_more,
.review_show_more {
  /*display:none;*/
  padding-top: 100px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.acknowledgements {
  color: #64686e;
  font-family: "Roboto-Condensed";
  font-size: 13pt;
  clear: both;
  padding: 20px 0px 0px 0px;
}
.show_more_container {
  border: solid green 0px;
  padding: 20px 0px 20px 0px;
  border-bottom: solid #dbdbdb 1px;
}
.about_the_authors {
  padding: 35px 0px 0px 0px;
  width: 100%;
  color: #64686e;
  font-family: "Roboto-Condensed";
  font-size: 13pt;
  line-height: 20px;
}
.about_the_authors p {
  color: #64686e;
  font-family: "Roboto-Condensed";
  font-size: 13pt;
  line-height: 20px;
}
/* Book */

/* Subject Landing  */
.subject_landing_bg {
  height: auto;
  padding-bottom: 55px;
  background-size: cover;
  border: solid red 0px;
}
.subject_landing_padding {
  padding: 10px 20px 20px 15px;
}
.subject_landing_padding .breadcrumb a {
  color: #ffffff;
  font-family: "Roboto-Regular", "arial";
  font-size: 14px;
}
.subject_landing_padding .breadcrumb span {
  color: #ffffff;
  font-family: "Roboto-Regular", "arial";
  font-size: 14px;
}
.subject_landing_padding .breadcrumb {
  background: none;
  padding: 0px;
  margin: 0px;
}
.subject_landing_bg .subject_land_intro_text {
  padding-top: 10px;
}
.subject_landing_bg .subject_land_intro_text p {
  clear: both;
  padding-top: 10px;
  color: #ffffff;
  font-family: "Roboto-Regular", "arial";
  font-size: 17px;
  line-height: 23px;
}
.lable_hlight_landing {
  color: #ffffff;
  font-family: "Roboto-Condensed-Bold", "arial";
  font-size: 24px;
}
.highlights_landing_bg {
  background: #f8f7f5;
}
.highlights_landing_bg .highlight_book_title {
  font-family: "Roboto-Regular", "arial";
  font-size: 17px;
  color: #2675b1;
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 5px;
}
.highlights_landing_bg .highlight_author_name {
  font-family: "Roboto-Light", "arial";
  font-size: 15px;
  line-height: 20px;
  color: #878a8f;
}
.landing_border {
  border-bottom: solid #dbdbdb 0px;
  padding-bottom: 50px;
}
.refine_section {
  background: #fff;
  border: solid red 0px;
}
.refine_padding {
  padding: 20px 20px 20px 15px;
}
.refine_heading {
  color: #747474;
  font-family: "Roboto-Condensed-Bold", "arial";
  font-size: 16px;
  width: 100%;
  padding-bottom: 5px;
  border-bottom: solid #d4d4d4 1px;
  text-transform: uppercase;
}
.sociology_lists {
  padding: 10px 0px 5px 0px;
  width: 100%;
  border-bottom: solid #d4d4d4 1px;
}
.sociology_lists1 {
  padding: 5px 0px 5px 0px;
  width: 15%;
  border-bottom: solid #d4d4d4 0px;
}
.lists_heading {
  color: #747474;
  font-family: "Roboto-Condensed-Bold", "arial";
  font-size: 16px;
}
.refine_lists_topics {
  padding-left: 0px;
  list-style: none;
}
.refine_lists_topics li {
  color: #2675b1;
  font-family: "Roboto-Regular", "arial";
  font-size: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.refine_lists_topics li a {
  color: #2675b1;
  font-family: "Roboto-Regular", "arial";
  font-size: 16px;
  padding: 0px;
}

.refine_lists_topics li a:link {
  text-decoration: none;
}
.refine_lists_topics li a:visited {
  text-decoration: none;
}
.refine_lists_topics li a:hover {
  text-decoration: underline;
}
.refine_lists_topics li a:active {
  text-decoration: none;
}

.more_lists a {
  text-transform: uppercase;
  color: #2675b1;
  font-family: "Roboto-Regular", "arial";
  font-size: 13px !important;
}
.refine_section .highlight_book_title_refine {
  font-family: "Roboto-Regular", "arial";
  font-size: 17px;
  float: left;
  padding: 30px 0px 0px 0px;
  color: #2675b1;
}
.refine_section .highlight_author_name_refine {
  font-family: "Roboto-Light", "arial";
  font-size: 15px;
  padding-top: 3px;
  float: left;
  color: #878a8f;
}
.second_row {
  padding: 30px 0px 0px 0px;
}
.third_row {
  padding: 30px 0px 0px 0px;
}
.overall_refine_image {
  border: solid green 0px;
  padding: 15px 0px 0px 0px;
}
.load_more {
  padding: 30px 0px 0px 0px;
}
.load_more_button {
  border: solid #6f9ec2 1px;
  padding: 5px;
  text-align: center;
  width: 20%;
  border-radius: 5px;
  margin: 0 auto;
  float: none;
}
.load_more_button a {
  font-family: "Roboto-Regular", "arial";
  font-size: 16px;
  color: #2675b1;
}
.load_more_button a:hover,
.load_more_button a:focus,
.load_more_button a:active {
  color: #2675b1;
  text-decoration: none;
}
.load_more_button {
  width: 45%;
}
/* Subject Landing  */

/* New Pages Design  */
.address_tag {
  font-size: 17px;
  font-family: "Roboto-Condensed-Bold", Arial;
  color: #808388;
  margin-bottom: 10px;
}
.reached_email {
  padding: 0px !important;
}
.question_font {
  font-size: 17px !important;
}
.license_heading {
  color: #000000 !important;
  font-size: 20px !important;
  font-family: "Roboto-Bold";
}
.terms_list {
  padding-left: 30px;
  padding-top: 5px;
  font-family: "Roboto-Regular", "arial";
  font-size: 17px;
  list-style-type: lower-alpha;
  color: #878a8f;
}

@media (max-width: 768px) {
  .social_icons_center {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 768px) {
  .series_bg_color {
    height: 150px;
  }

  .subject_bg_color {
    height: 150px;
  }
  .subjet-text a {
    height: 150px;
  }

  /*Header*/
  .menu_container {
    padding: 0px;
    margin-bottom: 10px;
  }

  .menu_container .navbar .navbar-nav li.padding-diff {
    padding-left: 25px;
  }
  .menu_container .navbar .navbar-nav li.padding-diff1 {
    padding-left: 17px;
  }
  .menu_container .navbar .navbar-nav {
    border: solid red 0px;
    margin-left: 35px;
    padding-top: 8px;
  }
  .menu_container .navbar .navbar-nav li a {
    font-family: "Roboto-Condensed-Bold", "arial";
    font-size: 14pt;
  }
  .menu_container .navbar .navbar-nav li {
    padding-top: 0px;
  }
  .menu_container .navbar .navbar-nav {
    padding-top: 8px;
  }
  .navbar-default .navbar-right .search_box {
    width: 240px;
  }
  .menu_container .btn {
    padding-right: 0px;
  }
  /*Header*/

  /*Slider*/
  .owl-theme .owl-controls .owl-buttons .owl-prev,
  .owl-theme .owl-controls .owl-buttons .owl-next {
    display: block !important;
  }

  .slider_padding {
    padding: 55px 20px 21px 45px;
  }
  .slider_content h1 {
    padding: 0px;
    font-size: 40px;
    text-align: left;
  }
  .slider_content {
    margin-top: 0px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .slider_content h3 {
    text-align: left;
    font-size: 18px;
  }
  /*Slider*/

  /*Highliths*/
  .review_container {
    padding-bottom: 60px;
  }
  /*Highliths*/

  /*News*/
  .news_padding {
    padding: 35px 0px 0px 0px;
  }
  .news_content {
    padding-top: 20px;
  }
  /*News*/

  /*Footer*/
  .footer_container .resource_connects p {
    text-align: left;
  }
  p.region {
    float: left;
    margin-right: 20px;
  }
  .select_region {
    float: left;
  }
  .stay_connect {
    padding-left: 30px;
    padding-top: 0px;
  }
  .footer_container .input-group {
    width: 92%;
  }
  .footer_container .input-group {
    float: left;
  }
  .footer_container .join-mail {
    font-size: 13pt;
    text-align: left;
  }
  .polity_img_footer {
    float: left;
    width: 6em;
  }
  .social_media_container .polity_img_footer {
    width: 6em;
    text-align: left;
  }
  .polity_footer .breadcrumb {
    width: 80%;
  }
  .breadcrumb_right ol {
    float: right;
    text-align: right;
  }
  .breadcrumb_right .breadcrumb a {
    font-size: 13pt;
  }
  .footer_container .join-mail {
    padding-top: 10px;
  }
  .breadcrumb_left {
    margin: 0px;
  }
  .modal-lg {
    width: 600px;
  }
  /*Footer*/

  /*Subject*/
  .subject_padding,
  .series_padding {
    padding: 10px 0px 20px 0px;
  }
  .subject_intro_text,
  .series_intro_text {
    padding-top: 15px;
  }
  .subject_list_bg .polity_subjects img {
    max-width: 95%;
    margin: 0px;
    border: solid green 0px;
  }
  /*Subject*/

  /*Series*/
  .subject_list_bg .polity_subjects img {
    max-width: 95%;
    margin: 0px;
    border: solid green 0px;
  }
  /*Series*/

  /*Blog*/
  .subject_padding .blog_container {
    width: 96%;
  }
  .blog_container .blog_title {
    font-size: 18px;
    padding-top: 15px;
    float: left;
    width: 230px;
    border: solid red 0px;
    padding-left: 10px;
    text-indent: 2px;
  }
  .blog_container .circle {
    width: 63px;
    height: 63px;
  }

  .blog_container .circle .ndate {
    font-size: 14pt;
    padding-top: 7px;
  }
  .blog_container .circle .nmonth {
    font-size: 11pt;
    padding-top: 0px;
  }

  .author_blog_title .circle {
    width: 63px;
    height: 63px;
  }

  .author_blog_title .circle .ndate {
    font-size: 14pt;
    padding-top: 7px;
    font-family: "Roboto-Condensed-Light", Arial;
    color: #64686e;
  }
  .author_blog_title .circle .nmonth {
    font-size: 11pt;
    font-family: "Roboto-Condensed-Light", Arial;
    padding-top: 0px;
    color: #64686e;
  }

  .subject_padding .blog_refine a {
    padding: 4px 7px;
    color: #2675b1;
    font-family: "Roboto-Condensed-Bold";
    font-size: 15px;
  }
  .subject_padding .blog_refine {
    font-family: "Roboto-Condensed-Bold";
    font-size: 16px;
    color: #747474;
  }
  .subject_padding .refine {
    padding: 20px 0px 10px 0px;
  }

  .subject_padding .blog_refine > li + li {
    padding: 0px 0px 0px 15px;
  }

  .subject_padding .blog_refine > li + li::before {
    content: none;
  }
  .subject_padding .blog_refine > li + li::before {
    content: none;
  }
  /*Blog*/

  /*Author*/
  .author_blog_text h1 {
    margin: -8px 0px 0px 0px;
    font-size: 40px;
    line-height: 45px;
  }
  .subject_intro_text .author_blog_text {
    padding-left: 0px;
    padding-right: 0px;
    border: solid green 0px;
  }
  .container .author_blog_text p {
    font-size: 17px;
    padding: 30px 0px 0px 0px;
  }
  .container .author_blog_text h3 {
    font-size: 25px;
    padding: 20px 0px 0px 0px;
  }
  .subject_intro_text .author_blog_page {
    padding-top: 30px;
  }
  /*Author*/

  /*Content*/
  .container .author_blog_text blockquote p {
    font-size: 24px;
    line-height: 35px;
  }
  /*Content*/

  /* Book */
  .books_images {
    float: left;
    display: block;
  }
  .books_description {
    padding-left: 15px;
  }
  .button_column {
    padding: 0px;
  }
  .author_name {
    text-align: left;
    padding-bottom: 7px;
    padding-top: 5px;
  }
  .tag_line_author {
    padding-left: 3px;
    text-align: left;
  }
  .american_century_heading {
    text-align: left;
  }
  .buy_now_overall {
    width: 100%;
  }
  .buy_now_overall1 {
    width: 100%;
  }
  .buy_now_button {
    width: 100%;
  }
  /* Book */

  /*Subject Landing */
  .load_more_button {
    width: 25%;
  }
  .sociology_lists1 {
    padding: 5px 0px 5px 0px;
    width: 30%;
    border-bottom: solid #d4d4d4 0px;
  }
  /*Subject Landing  */
}

@media (min-width: 992px) {
  .series_bg_color {
    height: 150px;
  }
  .subject_bg_color {
    height: 150px;
  }
  .subjet-text a {
    height: 150px;
  }

  /*Header*/
  .menu_container {
    padding: 0px;
    margin-bottom: 10px;
  }
  .menu_container .navbar .navbar-nav li.padding-diff {
    padding-left: 25px;
  }
  .menu_container .navbar .navbar-nav li.padding-diff1 {
    padding-left: 17px;
  }
  .menu_container .navbar .navbar-nav {
    border: solid red 0px;
    margin-left: 15px;
    padding-top: 8px;
  }
  .menu_container .navbar .navbar-nav li a {
    font-family: "Roboto-Condensed-Bold", "arial";
    font-size: 14pt;
  }
  .menu_container .navbar .navbar-nav li {
    padding-top: 0px;
  }

  .menu_container .navbar .navbar-nav {
    padding-top: 8px;
  }
  .navbar-default .navbar-right .search_box {
    width: 240px;
  }
  .menu_container .btn {
    padding-right: 0px;
  }
  /*Header*/

  /*Slider*/
  .owl-theme .owl-controls .owl-buttons .owl-prev,
  .owl-theme .owl-controls .owl-buttons .owl-next {
    display: block !important;
  }
  .slider_padding {
    padding: 55px 20px 21px 15px;
  }
  .slider_content h1 {
    padding: 0px;
    font-size: 40px;
    text-align: left;
  }
  .slider_content {
    margin-top: 0px;
    padding-left: 40px;
    padding-right: 50px;
  }
  .slider_content h3 {
    text-align: left;
    font-size: 18px;
  }
  /*Slider*/

  /*Highliths*/
  .review_container {
    padding-bottom: 60px;
  }
  /*Highliths*/

  /*News*/
  .news_padding {
    padding: 35px 0px 0px 0px;
  }
  .news_content {
    padding-top: 20px;
  }
  /*News*/

  /*Footer*/
  .footer_container .resource_connects p {
    text-align: left;
  }
  p.region {
    float: left;
    margin-right: 20px;
  }
  .select_region {
    float: left;
  }
  .stay_connect {
    padding-left: 30px;
    padding-top: 0px;
  }
  .footer_container .input-group {
    width: 92%;
  }
  .footer_container .input-group {
    float: left;
  }
  .footer_container .join-mail {
    font-size: 13pt;
    text-align: left;
  }
  .polity_img_footer {
    float: left;
    width: 6em;
  }
  .social_media_container .polity_img_footer {
    width: 6em;
    text-align: left;
  }
  .polity_footer .breadcrumb {
    width: 80%;
  }
  .breadcrumb_right ol {
    float: right;
    text-align: right;
  }
  .breadcrumb_right .breadcrumb a {
    font-size: 13pt;
  }
  .footer_container .join-mail {
    padding-top: 10px;
  }
  .breadcrumb_left {
    margin: 0px;
  }
  .modal-lg {
    width: 800px;
  }
  /*Footer*/

  /*Subject*/
  .subject_padding,
  .series_padding {
    padding: 10px 0px 20px 0px;
  }
  .subject_intro_text,
  .series_intro_text {
    padding-top: 15px;
  }
  .subject_list_bg .polity_subjects img {
    max-width: 100%;
    margin: 0px;
    border: solid blue 0px;
  }
  /*Subject*/

  /*Series*/

  .subject_list_bg .polity_series img {
    max-width: 100%;
    margin: 0px;
    border: solid blue 0px;
  }

  /*Series*/

  /*Blog*/
  .subject_padding .blog_container {
    width: 96%;
  }
  .blog_container .blog_title {
    float: left;
    width: 335px;
    border: solid red 0px;
    font-size: 22px;
    text-indent: 2px;
    padding-left: 10px;
    padding-top: 15px;
  }

  .blog_container .circle {
    width: 63px;
    height: 63px;
  }

  .blog_container .circle .ndate {
    font-size: 14pt;
    padding-top: 7px;
  }
  .blog_container .circle .nmonth {
    font-size: 11pt;
    padding-top: 0px;
  }
  .subject_padding .blog_refine > li + li::before {
    content: none;
  }
  /*Blog*/

  /*Author*/
  .author_blog_text h1 {
    font-size: 40px;
    line-height: 45px;
    margin: -8px 0px 0px 0px;
  }
  .subject_intro_text .author_blog_text {
    padding-left: 0px;
    padding-right: 0px;
    border: solid 0px blue;
  }
  .container .author_blog_text p {
    font-size: 17px;
    padding: 30px 0px 0px 0px;
  }
  .container .author_blog_text h3 {
    font-size: 25px;
    padding: 20px 0px 0px 0px;
  }
  .subject_intro_text .author_blog_page {
    padding-top: 30px;
  }
  /*Author*/

  /*Content*/
  .container .author_blog_text blockquote p {
    font-size: 24px;
    line-height: 35px;
  }
  /*Content*/

  /* Book */
  .books_images {
    float: left;
    display: block;
  }
  .books_description {
    padding-left: 40px;
  }
  .buy_now_overall1 {
    width: 100%;
  }
  .buy_now_overall {
    width: 100%;
  }
  .buy_now_button {
    width: 100%;
  }
  .author_name {
    text-align: left;
    padding-bottom: 7px;
    padding-top: 5px;
  }
  .tag_line_author {
    padding-left: 3px;
    text-align: left;
  }
  .american_century_heading {
    text-align: left;
  }
  /* Book */
  /*Subject Landing */
  .load_more_button {
    width: 20%;
  }
  /*Subject Landing  */
}

@media (min-width: 1200px) {
  .series_bg_color {
    height: 150px;
  }
  .subject_bg_color {
    height: 150px;
  }
  .subjet-text a {
    height: 150px;
  }

  /*Header*/
  .menu_container {
    margin-bottom: 10px;
    padding: 0px;
  }
  .menu_container .navbar .navbar-nav {
    border: solid red 0px;
    margin-left: 15px;
    padding-top: 8px;
  }

  .menu_container .navbar .navbar-nav li.padding-diff {
    padding-left: 25px;
  }
  .menu_container .navbar .navbar-nav li.padding-diff1 {
    padding-left: 17px;
  }

  .menu_container .navbar .navbar-nav li a {
    font-family: "Roboto-Condensed-Bold", "arial";
    font-size: 14pt;
  }

  .menu_container .navbar .navbar-nav li {
    padding-top: 0px;
  }

  .menu_container .navbar .navbar-nav {
    padding-top: 8px;
  }

  .navbar-default .navbar-right .search_box {
    width: 240px;
  }

  .menu_container .btn {
    padding-right: 0px;
  }
  /*Header*/

  /*Slider*/
  .owl-theme .owl-controls .owl-buttons .owl-prev,
  .owl-theme .owl-controls .owl-buttons .owl-next {
    display: block !important;
  }
  .slider_padding {
    padding: 55px 20px 21px 15px;
  }
  .slider_content h1 {
    padding: 0px;
    font-size: 40px;
    text-align: left;
  }
  .slider_content {
    margin-top: 0px;
    padding-left: 40px;
    padding-right: 50px;
  }
  .slider_content h3 {
    text-align: left;
    font-size: 18px;
  }
  /*Slider*/

  /*Highliths*/
  .review_container {
    padding-bottom: 60px;
  }
  /*Highliths*/

  /*News*/
  .news_padding {
    padding: 35px 0px 0px 0px;
  }
  .news_content {
    padding-top: 20px;
  }
  /*News*/

  /*Footer*/
  .footer_container .resource_connects p {
    text-align: left;
  }
  p.region {
    float: left;
    margin-right: 20px;
  }
  .select_region {
    float: left;
  }
  .stay_connect {
    padding-left: 30px;
    padding-top: 0px;
  }
  .footer_container .input-group {
    width: 92%;
  }
  .footer_container .input-group {
    float: left;
  }
  .footer_container .join-mail {
    font-size: 13pt;
    text-align: left;
  }
  .polity_img_footer {
    float: left;
    width: 6em;
  }
  .social_media_container .polity_img_footer {
    width: 6em;
    text-align: left;
  }
  .polity_footer .breadcrumb {
    width: 80%;
  }
  .breadcrumb_right ol {
    float: right;
    text-align: right;
  }
  .breadcrumb_right .breadcrumb a {
    font-size: 13pt;
  }
  .footer_container .join-mail {
    padding-top: 10px;
  }
  .breadcrumb_left {
    margin: 0px;
  }
  .modal-lg {
    width: 1100px;
  }
  /*Footer*/

  /*Subject*/
  .subject_padding,
  .series_padding {
    padding: 10px 0px 20px 0px;
  }
  .subject_intro_text,
  .series_intro_text {
    padding-top: 15px;
  }
  .subject_list_bg .polity_subjects img {
    margin: 0px;
    max-width: 100%;
    border: solid red 0px;
  }
  /*Subject*/

  /*Series*/
  .subject_list_bg .polity_series img {
    margin: 0px;
    max-width: 100%;
    border: solid red 0px;
  }
  /*Series*/

  /*Blog*/
  .subject_padding .blog_container {
    width: 96%;
  }
  .blog_container .blog_title {
    text-indent: 2px;
    padding-left: 10px;
    width: 335px;
    font-size: 22px;
    padding-top: 15px;
  }
  .blog_container .circle {
    width: 63px;
    height: 63px;
  }

  .blog_container .circle .ndate {
    font-size: 14pt;
    padding-top: 7px;
  }
  .blog_container .circle .nmonth {
    font-size: 11pt;
    padding-top: 0px;
  }
  .subject_padding .blog_refine > li + li::before {
    content: none;
  }
  /*Blog*/

  /*Author*/
  .author_blog_text h1 {
    margin: -8px 0px 0px 0px;
    font-size: 32pt;
    line-height: 45px;
    font-family: "Roboto-Condensed-Light", "arial";
  }
  .subject_intro_text .author_blog_text {
    padding-left: 0px;
    padding-right: 0px;
    border: solid yellow 0px;
  }
  .container .author_blog_text p {
    font-size: 17px;
    padding: 30px 0px 0px 0px;
  }
  .container .author_blog_text h3 {
    font-size: 25px;
    padding: 20px 0px 0px 0px;
  }
  .subject_intro_text .author_blog_page {
    padding-top: 30px;
  }
  /*Author*/

  /*Content*/
  .container .author_blog_text blockquote p {
    font-size: 24px;
    line-height: 35px;
  }
  /*Content*/

  /* Book */
  .books_images {
    float: left;
    display: block;
  }
  .books_description {
    padding-left: 40px;
  }
  .button_tag_name {
    width: 90%;
  }
  .order_exam_btn_tag {
    width: 90%;
  }
  .buy_now_overall {
    width: 100%;
  }
  .buy_now_overall1 {
    width: 100%;
  }
  .button_column {
    padding: 0 0 0 15px;
  }
  .order_exam_button {
    width: 90%;
  }
  .buy_now_button {
    width: 90%;
  }
  .american_century_heading {
    margin-top: -5px;
    text-align: left;
    font-size: 32pt;
  }
  .author_name {
    margin: 0px 0 0;
    padding-bottom: 7px;
    padding-top: 5px;
    text-align: left;
  }
  .tag_line_author {
    padding-left: 3px;
    text-align: left;
  }
  /* Book */

  /* Subject Landing  */
  .subject_landing_bg {
    background-size: 100% 100%;
  }
  .subject_landing_padding {
    padding: 10px 0px 20px 0px;
  }
  .refine_padding {
    padding: 30px 0px 20px 0px;
  }
  .overall_refine_image {
    border: solid green 0px;
    padding: 15px 0px 0px 20px;
  }
  .load_more_button {
    width: 20%;
  }
  /* Subject Landing  */
}

.container .search_result_text p {
  font-size: 13px;
}
.container .search_result_text .search_title {
  font-size: 15px;
  font-family: "Roboto-Condensed-Bold";
}
.screen-reader-text {
  display: none;
}

.subject_padding_new {
  padding: 0px 0px 10px 13px;
}
.subjects_ajax {
  display: none;
}

.refine_lists_topics li a.refine_subject_selected {
  background-color: #2675b1;
  color: #fff;
  padding: 4px;
}

.blog_load_more_container {
  padding-top: 20px;
}

.blog_load_more_container .blog_load_more {
  margin: 0px auto;
  width: 120px;
}
.blog_load_more_container .blog_load_more a {
  text-align: center;
  display: block;
  width: 120px;
  border: solid #6f9ec2 1px;
  border-radius: 3px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: "Roboto-Condensed";
  font-size: 16px;
}
.blog_load_more_container .blog_load_more a:link {
  text-decoration: none;
}

.blog_load_more_container .blog_load_more a:visited {
  text-decoration: none;
}

.blog_load_more_container .blog_load_more a:hover {
  text-decoration: none;
}

.blog_load_more_container .blog_load_more a:active {
  text-decoration: none;
}

.address_tag1 {
  font-size: 17px;
  font-family: "Roboto-Regular", Arial;
  color: #000;
  margin-bottom: 10px;
}
.address_tag2 {
  font-size: 17px;
  font-family: "Roboto-Regular", Arial;
  color: #000;
  margin-top: 20px;
  margin-bottom: 10px;
}
.polity_bold {
  font-size: 17px;
  color: #000 !important;
  font-family: "Roboto-Bold", Arial !important;
}
.queries_heading {
  color: #000000 !important;
  font-size: 18px !important;
  font-family: "Roboto-Regular", Arial !important;
}
.terms_list1 {
  padding-left: 15px;
  padding-top: 5px;
  font-family: "Roboto-Regular", "arial";
  font-size: 17px;
  color: #878a8f;
}
.terms_list_sociology {
  padding-left: 0px;
  padding-top: 30px;
  font-family: "Roboto-Regular", "arial";
  font-size: 17px;
  color: #878a8f;
  line-height: 32px;
}
.terms_list_sociology li a {
  font-weight: bold;
}
.us_font {
  font-size: 16px !important;
  color: #000 !important;
}
.inspection_heading {
  color: #000000 !important;
  font-size: 20px !important;
  font-family: "Roboto-Regular", Arial !important;
}
.col-centered_mobile {
  float: none;
  display: table;
  margin: 0 auto;
  width: 50%;
  text-align: left;
}
.dropdown-menu {
  margin: -1px 0px 0px -118px;
  min-width: 154px;
  border-radius: 0px;
  background: #f8f7f5;
  padding: 0px;
}
.dropdown-menu li {
  border-bottom: solid #ccc 1px;
}
.dropdown-menu > li > a {
  padding: 10px 20px;
}

/* New Styles */
.news_section_para {
  font-family: "Roboto-Italic", Arial !important;
  font-size: 17px !important;
  color: #343434 !important;
  margin-bottom: 5px;
  padding: 20px 0px 0px 0px !important;
}
.resurgence_bold {
  font-size: 17px !important;
  color: #343434 !important;
  font-family: "Roboto-Condensed-Bold" !important;
}
.heading_para_font {
  font-size: 20px !important;
  color: #343434 !important;
  padding: 5px 0px 0px 0px !important;
  font-family: "Roboto-Condensed-Bold" !important;
  margin: 0px;
}
.survival_news {
  clear: both;
  float: left;
  width: 100%;
  padding: 0px !important;
}
.february_font {
  font-size: 17px !important;
  color: #343434 !important;
  font-family: "Roboto-Regular", Arial !important;
}
.link_polity_books {
  clear: both !important;
  float: left;
  padding: 5px 0px 0px 0px;
  width: 100%;
}
.politics_font {
  padding: 15px 0px 0px 0px;
  float: left;
}

.politics_font h3.heading_para_font {
  margin: 0px;
}

.radio_button_msg {
  padding: 5px 0px 0px 0px !important;
}
/* New Styles */
.radio_button_msg {
  padding: 5px 0px 5px 0px !important;
}
.radio_button_msg_second {
  padding: 15px 0px 0px 0px !important;
}
.main_background {
  padding: 5px 0px;
}

.wpcf7-form-control.wpcf7-text {
  width: 100%;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  color: #555;
  display: block;
  font-size: 14px;
  height: 34px;
  line-height: 1.42857;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}
.wpcf7-form-control.wpcf7-select.title_class {
  width: 100%;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  color: #555;
  display: block;
  font-size: 14px;
  height: 34px;
  line-height: 1.42857;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}
.wpcf7-form-control.wpcf7-select.wpcf7-validates-as-required {
  width: 100%;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  color: #555;
  display: block;
  font-size: 14px;
  height: 34px;
  line-height: 1.42857;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}
.name_bold {
  font-size: 14px;
}
.txtclr {
  color: #f00;
  font-size: 14px;
}
label {
  font-weight: normal;
  font-family: "Roboto-Regular";
}
select[multiple],
select[size] {
  height: auto !important;
}
.wpcf7-form-control.wpcf7-submit {
  user-select: none;
  -moz-user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857;
  margin-bottom: 0;
  padding: 6px 25px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  background-color: #fff;
  border-color: #ccc;
  color: #333;
}
.wpcf7-form-control.wpcf7-submit:hover {
  background-color: #e6e6e6 !important;
  border-color: #adadad !important;
  color: #333 !important;
}
.link_polity_books a {
  font-size: 17px;
  font-family: "Roboto-Condensed";
}

.subject_books {
  height: 220px;
  width: 145px;
  -webkit-box-shadow: 5px 4px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 4px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 4px 5px 0px rgba(0, 0, 0, 0.75);
}
.subject_padding .blog_refine a.blog_refie_selected {
  color: #747474;
  text-decoration: none;
}
.show_more_text {
  visibility: visible;
}
.showmoretxtDesc {
  cursor: pointer;
}
.books_images img {
  width: 250px;
  height: 372px;
}
.books_images {
  -webkit-box-shadow: 5px 4px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 4px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 4px 5px 0px rgba(0, 0, 0, 0.75);
}
.book_no_image {
  padding-top: 135px;
  width: 222px;
  height: 335px;
  background-color: #3d6c9f;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  font-size: 40px;
  font-family: "Roboto-Black";
}
.slider_img_shadow a {
  text-decoration: none;
}

#breadcrumbs {
  padding: 0px;
  margin: 0px;
  list-style: none;
  overflow: hidden;
}

#breadcrumbs li {
  display: inline-block;
  vertical-align: middle;
  padding-left: 5px;
  color: #fff;
}

#breadcrumbs .separator {
  font-size: 15px;
  font-weight: 100;
  color: #ccc;
}

.bread-current {
  color: #808388;
  font-family: "Roboto-Regular", "arial";
  font-size: 14px;
}

.slider_shadow {
  -webkit-box-shadow: 5px 4px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 4px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 4px 5px 0px rgba(0, 0, 0, 0.75);
}

.slider_img_shadow {
  width: 135px;
  height: 204px;
  -webkit-box-shadow: 5px 4px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 4px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 4px 5px 0px rgba(0, 0, 0, 0.75);
}

.book_cover_image {
  height: 204px;
  width: 135px;
  max-width: 135px;
  max-height: 204px;
}

.cover_no_image {
  padding-top: 101px;
  width: 135px;
  height: 204px;
  background-color: #3d6c9f;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  font-size: 20px;
  font-family: "Roboto-Black";
}

.show_more_text ul {
  width: 100%;
  clear: both;
}

.show_more_text ul li {
  width: 100%;
  clear: both;
}

.toc1 {
  float: left;
  width: 100%;
  position: relative;
}
.toc2 {
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 10px;
}
.toc1_height {
  height: 290px;
}
.toc2_height {
  height: 260px;
}

.review1 {
  float: left;
  width: 100%;
  position: relative;
}
.review2 {
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 10px;
}
.review1_height {
  height: 190px;
}
.review2_height {
  height: 160px;
}
.new_author_container {
  float: left;
  width: 100%;
  padding-bottom: 25px;
  border: solid red 0px;
  padding-top: 5px;
}

/*Pages*/
.contactmenu {
  padding: 30px 0px 20px 15px;
  font-family: "Roboto-Regular", "arial";
  font-size: 17px;
  color: #878a8f;
  line-height: 32px;
  width: 100%;
  border-bottom: 1px solid #ccc;
}
.contactmenu li a {
  font-weight: bold;
}
.contactmenu li a:hover {
  text-decoration: none;
}
.contactmenu1 {
  padding: 30px 0px 0px 15px;
  font-family: "Roboto-Regular", "arial";
  font-size: 17px;
  color: #878a8f;
  line-height: 32px;
  width: 100%;
}
.contactmenu1 li a {
  font-weight: bold;
}
.contactmenu1 li a:hover {
  text-decoration: none;
}
.up_triangle {
  width: 0;
  height: 0;
  float: right;
  border-bottom: 15px solid black;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  padding: 5px 0 0 !important;
}
.hr_line_contact {
  border: solid #ccc 1px;
  float: left;
  width: 100%;
}
.spnTop {
  padding: 3px 0px 5px 0px !important;
  float: right;
  cursor: pointer;
}
.address_tag3 {
  font-size: 17px;
  font-family: "Roboto-Regular", Arial;
  color: #000;
  padding: 15px 0px 0px 0px;
  margin-bottom: 10px;
}

/*Pages*/

@media (min-width: 768px) {
  .slider_img_shadow {
    width: 185px;
    height: 283px;
    -webkit-box-shadow: 5px 4px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 5px 4px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 5px 4px 5px 0px rgba(0, 0, 0, 0.75);
  }

  .book_cover_image {
    height: 284px;
    width: 186px;
    max-width: 186px;
    max-height: 284px;
  }

  .cover_no_image {
    padding-top: 141px;
    width: 186px;
    height: 284px;
    background-color: #3d6c9f;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    font-size: 27px;
    font-family: "Roboto-Black";
  }

  .books_images img {
    width: 222px;
    height: 335px;
  }

  .subject_padding .blog_refine a.blog_refie_selected {
    color: #747474;
    text-decoration: none;
  }

  .subject_padding_new {
    padding: 10px 0px 20px 0px;
  }
  .col-centered_mobile {
    float: left;
    width: 41.6667%;
    display: block;
    text-align: center;
  }
  .wpcf7-form-control.wpcf7-text {
    width: 75% !important;
  }
  .wpcf7-form-control.wpcf7-select.wpcf7-validates-as-required {
    width: 75%;
  }
  .wpcf7-form-control.wpcf7-select.title_class {
    width: 75%;
  }
}

@media (min-width: 992px) {
  .slider_img_shadow {
    width: 185px;
    height: 283px;
    -webkit-box-shadow: 5px 4px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 5px 4px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 5px 4px 5px 0px rgba(0, 0, 0, 0.75);
  }

  .book_cover_image {
    height: 284px;
    width: 186px;
    max-width: 186px;
    max-height: 284px;
  }

  .cover_no_image {
    padding-top: 141px;
    width: 186px;
    height: 284px;
    background-color: #3d6c9f;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    font-size: 27px;
    font-family: "Roboto-Black";
  }

  .books_images img {
    width: 222px;
    height: 335px;
  }

  .subject_padding .blog_refine a.blog_refie_selected {
    color: #747474;
    text-decoration: none;
  }
  .subject_padding_new {
    padding: 10px 0px 20px 0px;
  }
  .col-centered_mobile {
    float: left;
    width: 33.333%;
    display: block;
    text-align: center;
  }
  .wpcf7-form-control.wpcf7-text {
    width: 50% !important;
  }
  .wpcf7-form-control.wpcf7-select.wpcf7-validates-as-required {
    width: 50%;
  }
  .wpcf7-form-control.wpcf7-select.title_class {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .slider_img_shadow {
    width: 185px;
    height: 283px;
    -webkit-box-shadow: 5px 4px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 5px 4px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 5px 4px 5px 0px rgba(0, 0, 0, 0.75);
  }

  .book_cover_image {
    height: 284px;
    width: 186px;
    max-width: 186px;
    max-height: 284px;
  }

  .cover_no_image {
    padding-top: 141px;
    width: 186px;
    height: 284px;
    background-color: #3d6c9f;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    font-size: 27px;
    font-family: "Roboto-Black";
  }

  .books_images img {
    width: 222px;
    height: 335px;
  }
  .subject_padding .blog_refine a.blog_refie_selected {
    color: #747474;
    text-decoration: none;
  }
  .subject_padding_new {
    padding: 10px 0px 20px 0px;
  }
  .col-centered_mobile {
    float: left;
    width: 33.333%;
    text-align: center;
    display: block;
  }

  .wpcf7-form-control.wpcf7-text {
    width: 50% !important;
  }
  .wpcf7-form-control.wpcf7-select.wpcf7-validates-as-required {
    width: 50%;
  }
  .wpcf7-form-control.wpcf7-select.title_class {
    width: 50%;
  }
}

.no_blogs {
  font-family: "Roboto-Condensed-Bold" !important;
  display: none;
  font-size: 18pt;
}

.goto_top_arrow {
  border: 0 solid red;
  float: left;
  width: 45px;
  font-size: 16px !important;
  color: #fff !important;
  font-family: "Glyphicons Halflings", Arial !important;
}
.goto_top_arrow_font {
  color: #fff !important;
  font-size: 13px !important;
}
.gototop {
  background-color: #337ab7;
  border: 3px solid #fff;
  border-radius: 2px;
  bottom: 100px;
  color: #fff;
  cursor: pointer;
  display: none;
  line-height: 14px;
  opacity: 0.5;
  padding-bottom: 6px;
  padding-top: 8px;
  position: fixed;
  right: 15px;
  text-align: center;
  width: 50px;
  z-index: 8;
}
.typeof_radio span {
  color: #808388;
}
.checkbox_mail span {
  color: #808388;
}

span.wpcf7-not-valid-tip {
  color: #f00 !important;
  font-size: 14px !important;
  display: block;
}
.txtclr {
  color: #f00 !important;
  font-size: 14px;
}
.div.wpcf7-response-output {
  display: none !important;
}

.subscribe_lists_1 {
  width: 100%;
  margin: 0px;
  text-align: center;
  border: 0 solid red;
  color: #0860d4 !important;
  float: left;
  font-size: 14px !important;
}
.label_join_list {
  width: 100%;
  text-align: center;
}

@media (min-width: 768px) {
  .subscribe_lists_1 {
    width: 40%;
    margin: 8px 0px;
    border: 0 solid red;
    color: #0860d4 !important;
    float: left;
    font-size: 14px !important;
  }
  .label_join_list {
    width: 60%;
  }
}
@media (min-width: 992px) {
  .subscribe_lists_1 {
    width: 50%;
    margin: 8px 0px;
    text-align: center;
    border: 0 solid red;
    color: #0860d4 !important;
    float: left;
    font-size: 14px !important;
  }
  .label_join_list {
    width: 45%;
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .subscribe_lists_1 {
    width: 25%;
    margin: 8px 0px;
    text-align: center;
    border: 0 solid red;
    color: #0860d4 !important;
    float: left;
    cursor: pointer;
    font-size: 14px !important;
  }
  .label_join_list {
    width: 45%;
    text-align: center;
  }
}
.close_border {
  border: #ccc solid 1px;
}
.close_border:hover {
  border: #ccc solid 1px;
}

/* Today CSS Changes 05-15-2015*/

.sitemap_title {
  background: #3d6c9f;
  padding: 10px 0px 10px 10px;
  color: #fff;
  font-size: 24px;
  float: left;
  width: 100%;
}
.sitemap_content li {
  list-style-type: none;
  font-size: 16px;
  padding: 10px 0px 10px 0px;
  border-bottom: solid #ccc 1px;
}
.sitemap_content li a {
  font-size: 16px;
  padding-left: 25px;
  font-family: "Roboto-Condensed", Arial;
}
.subjects_sitemap_lists_padding {
  padding-left: 0px;
  margin-bottom: 0px;
}
.subjects_sitemap_lists_padding li a {
  padding-left: 50px !important;
}
.subject_color_change {
  color: #337ab7 !important;
}
.sitemap_content {
  float: left;
  width: 100%;
  background-color: #fff;
  padding-bottom: 30px;
}
.authors_sitemap_lists {
  padding: 10px 0px 0px 0px;
  margin-bottom: 0px;
  font-size: 16px;
  font-family: "Roboto-Condensed", Arial;
}
.sitemap_content li a span {
  color: #3b3936;
}
.subject_lists_padding {
  padding-left: 25px !important;
}
.cover_no_image_authors {
  width: 135px;
  height: 170px;
  background-color: #3d6c9f;
  text-align: center;
  color: #fff;
  padding-top: 70px;
  vertical-align: middle;
  font-size: 27px;
  font-family: "Roboto-Black";
}
.highlight_author_name {
  float: left;
  clear: none;
  padding-top: 5px;
  font-family: "Roboto-Light", Arial;
  font-size: 15px;
  padding-bottom: 0px;
}
.author_res_change {
  width: 90% !important;
}
@media (min-width: 768px) {
  .cover_no_image_authors {
    width: 230px;
    height: 279px;
    background-color: #3d6c9f;
    text-align: center;
    color: #fff;
    padding-top: 140px;
    vertical-align: middle;
    font-size: 27px;
    font-family: "Roboto-Black";
  }
  .author_res_change {
    width: 100% !important;
  }
}
@media (min-width: 992px) {
  .author_res_change {
    width: 100% !important;
  }
  .cover_no_image_authors {
    width: 250px;
    height: 317px;
    background-color: #3d6c9f;
    text-align: center;
    color: #fff;
    padding-top: 140px;
    vertical-align: middle;
    font-size: 27px;
    font-family: "Roboto-Black";
  }
}
@media (min-width: 1200px) {
  .polity_footer .breadcrumb {
    font-size: 11pt;
  }

  .circle {
    border-radius: 30px;
  }

  .author_res_change {
    width: 100% !important;
  }
  .cover_no_image_authors {
    width: 250px;
    height: 317px;
    background-color: #3d6c9f;
    text-align: center;
    color: #fff;
    padding-top: 140px;
    vertical-align: middle;
    font-size: 27px;
    font-family: "Roboto-Black";
  }
}
.author_blog_link {
  padding-top: 5px !important;
}

.author_avatar {
  width: 135px;
  height: 170px;
}
@media (min-width: 768px) {
  .author_avatar {
    width: 220px;
    height: 279px;
  }
  .author_avatar img {
    max-width: 100% !important;
  }
}
@media (min-width: 992px) {
  .author_avatar {
    width: 250px;
    height: 317px;
  }
  .author_avatar img {
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .author_avatar {
    width: 250px;
    height: 317px;
  }
  .author_avatar img {
    max-width: 100%;
  }
}
.author_avatar img {
  max-width: 100%;
}
.blog_title_new {
  padding-top: 0px;
  padding-left: 10px;
  margin: 0px;
  font-size: 40px;
  color: #2675b1;
  font-family: "Roboto-Condensed-Light", Arial;
}
.blog_tagline_info {
  float: left;
  clear: both;
  width: 100%;
  padding: 5px 0px 15px 10px;
  color: #337ab7;
  font-family: "Roboto-Condensed", Arial;
}
.author_image_blog {
  display: block;
  float: left;
  padding-right: 35px;
  padding-bottom: 20px;
}
.author_description_content p {
  float: none;
  clear: none !important;
}
.author_desc_title {
  margin: 5px 0px 5px 0px;
  color: #000;
  font-family: "Roboto-Condensed-Light", Arial;
  font-size: 32pt;
  padding-top: 0;
}
.subject_landing_bg .subject_land_intro_text p a:link {
  color: #fff;
}

.subject_landing_bg .subject_land_intro_text p a:visited {
  color: #fff;
}

.subject_landing_bg .subject_land_intro_text p a:hover {
  color: #fff;
}

.subject_landing_bg .subject_land_intro_text p a:active {
  color: #fff;
}
.cover_no_image_authors1 {
  width: 250px;
  height: 317px;
  background-color: #3d6c9f;
  text-align: center;
  color: #fff !important;
  padding-top: 140px !important;
  vertical-align: middle;
  font-size: 27px !important;
  font-family: "Roboto-Black";
}

.inline {
  display: inline-block;
}
.wrap {
  display: table;
  height: auto;
}
.wrap a {
  display: table-cell;
  vertical-align: middle;
}
.blog_title_new1 {
  margin-top: 0px;
}
.featured_author .slider_img_shadow {
  height: 170px;
  border: solid red 0px;
}

.featured_author .cover_no_image {
  height: 170px;
  padding-top: 85px;
}
@media (min-width: 768px) {
  .featured_author .slider_img_shadow {
    height: 234px;
  }

  .featured_author .cover_no_image {
    height: 234px;
    padding-top: 120px;
  }

  .cover_no_image_authors1 {
    width: 250px;
    height: 317px;
    background-color: #3d6c9f;
    text-align: center;
    color: #fff !important;
    padding-top: 140px !important;
    vertical-align: middle;
    font-size: 27px !important;
    font-family: "Roboto-Black";
  }
  .inline {
    display: inline-block;
  }
  .wrap {
    display: table;
    height: 100px;
  }
  .wrap a {
    display: table-cell;
    vertical-align: middle;
  }
  .blog_title_new1 {
    margin-top: -20px;
  }
}
@media (min-width: 992px) {
  .cover_no_image_authors1 {
    width: 250px;
    height: 317px;
    background-color: #3d6c9f;
    text-align: center;
    color: #fff !important;
    padding-top: 140px !important;
    vertical-align: middle;
    font-size: 27px !important;
    font-family: "Roboto-Black";
  }
  .inline {
    display: inline-block;
  }
  .wrap {
    display: table;
    height: 100px;
  }
  .wrap a {
    display: table-cell;
    vertical-align: middle;
  }
  .blog_title_new1 {
    margin-top: -20px;
  }
}
@media (min-width: 1200px) {
  .cover_no_image_authors1 {
    width: 250px;
    height: 317px;
    background-color: #3d6c9f;
    text-align: center;
    color: #fff !important;
    padding-top: 140px !important;
    vertical-align: middle;
    font-size: 27px !important;
    font-family: "Roboto-Black";
  }
  .inline {
    display: inline-block;
  }
  .wrap {
    display: table;
    height: 100px;
  }
  .wrap a {
    display: table-cell;
    vertical-align: middle;
  }
  .blog_title_new1 {
    margin-top: -20px;
  }
}

.event_title {
  font-family: "Roboto-Regular", Arial !important;
  font-size: 20px !important;
  padding: 10px 0px 5px 15px;
  background: #fff;
  margin: 0px !important;
}
.event_venue {
  font-family: "Roboto-Regular", Arial !important;
  font-size: 15px !important;
  padding: 0px 0px 0px 15px;
  background: #fff;
}
.event_venue1 {
  font-family: "Roboto-Regular", Arial !important;
  font-size: 15px !important;
  padding: 0px 0px 0px 15px;
  background: #fff;
}
.event_venue2 {
  font-family: "Roboto-Regular", Arial !important;
  font-size: 15px !important;
  padding: 0px 0px 10px 15px;
  background: #fff;
}
.event_title_name {
  font-family: "Roboto-Condensed-Bold", Arial !important;
  font-size: 20px !important;
}
.event_container {
  border-bottom: solid #ccc 1px;
}
.author_blog_page1 {
  padding-top: 20px;
  border-top: solid red 0px;
}
.event_venue_view {
  font-family: "Roboto-Regular", Arial !important;
  font-size: 32px !important;
  padding: 0px 0px 0px 15px;
  background: #fff;
}
.author_desc_title_view {
  margin: 5px 0px 5px 0px;
  color: #000;
  font-family: "Roboto-Condensed-Light", Arial;
  font-size: 32pt;
  padding-top: 0;
}

.author_breadcum {
  color: #808388 !important;
  font-family: "Roboto-Regular", "arial";
  font-size: 14px;
}

.compelling_argument {
  font-family: "Roboto-Italic", Arial !important;
  color: #385623 !important;
  font-size: 17px !important;
  padding: 5px 0px 0px 0px !important;
}

.blog_content img {
  margin-right: 20px;
  margin-top: 5px;
  clear: both;
}
.blog_title_single.pull-left {
  float: left;
  width: 90%;
}
.author_blog_text h1.media_h1 {
  font-family: "Roboto-Condensed-Bold";
  font-size: 35px;
  color: #3b3936;
}
.media_container h4 {
  padding-top: 15px;
}
.toc1_1 {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.toc1_1 * {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.wpcf7-list-item-label {
  font-family: "Roboto-Regular", "arial";
  font-size: 17px;
  color: #808388;
}
.subject_padding .tag_breadcrumb a {
  color: #337ab7;
}

.tag_breadcrumb > li + li:before {
  content: ",";
  color: #337ab7;
  padding: 0px 5px 0px 0px;
}
.contactmenu a {
  cursor: pointer;
}

.container .author_blog_mediatext p {
  font-size: 17px;
  padding: 0px 0 0;
}

.polity_authors.author_card {
  min-height: 200px;
  padding: 15px 0;
}
 .author-rows{clear:both !important;}

 .see-all-container{margin-top: 10px !important; }
 .see-all-container a{color: #000000 !important;}
 .polity_authors.author_card .title, .see-all-container a {
  font-family: "Roboto-Regular", Arial;
  font-size: 17px;
}

.row.alphabet {
  display: flex;
  width: 100%;
  justify-content: center;
  max-width: 100%;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.row.alphabet a {
  padding: 0 1rem;
}

.row.alphabet a.active{color:#000 !important;}
.alphabet-pt-10{margin-top: 10px;}

.authors-list__author a{
  padding-top: 15px;
  clear: both;
  font-family: "Roboto-Regular", Arial;
  font-size: 17px;
  color: #2675b1;
}